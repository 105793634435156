exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-funktioner-erbjudanden-js": () => import("./../../../src/pages/funktioner/erbjudanden.js" /* webpackChunkName: "component---src-pages-funktioner-erbjudanden-js" */),
  "component---src-pages-funktioner-index-js": () => import("./../../../src/pages/funktioner/index.js" /* webpackChunkName: "component---src-pages-funktioner-index-js" */),
  "component---src-pages-hitta-js": () => import("./../../../src/pages/hitta.js" /* webpackChunkName: "component---src-pages-hitta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-historia-js": () => import("./../../../src/pages/om/historia.js" /* webpackChunkName: "component---src-pages-om-historia-js" */),
  "component---src-pages-om-index-js": () => import("./../../../src/pages/om/index.js" /* webpackChunkName: "component---src-pages-om-index-js" */),
  "component---src-pages-om-villkor-test-js": () => import("./../../../src/pages/om/villkor-test.js" /* webpackChunkName: "component---src-pages-om-villkor-test-js" */),
  "component---src-pages-pris-index-js": () => import("./../../../src/pages/pris/index.js" /* webpackChunkName: "component---src-pages-pris-index-js" */),
  "component---src-pages-samarbete-egeryds-js": () => import("./../../../src/pages/samarbete/egeryds.js" /* webpackChunkName: "component---src-pages-samarbete-egeryds-js" */),
  "component---src-pages-samarbete-index-js": () => import("./../../../src/pages/samarbete/index.js" /* webpackChunkName: "component---src-pages-samarbete-index-js" */),
  "component---src-pages-samarbete-kone-js": () => import("./../../../src/pages/samarbete/kone.js" /* webpackChunkName: "component---src-pages-samarbete-kone-js" */),
  "component---src-pages-samarbete-parakey-js": () => import("./../../../src/pages/samarbete/parakey.js" /* webpackChunkName: "component---src-pages-samarbete-parakey-js" */),
  "component---src-pages-skaffa-index-js": () => import("./../../../src/pages/skaffa/index.js" /* webpackChunkName: "component---src-pages-skaffa-index-js" */),
  "component---src-pages-support-admin-js": () => import("./../../../src/pages/support/admin.js" /* webpackChunkName: "component---src-pages-support-admin-js" */),
  "component---src-pages-support-app-js": () => import("./../../../src/pages/support/app.js" /* webpackChunkName: "component---src-pages-support-app-js" */),
  "component---src-pages-support-fragor-svar-js": () => import("./../../../src/pages/support/fragor-svar.js" /* webpackChunkName: "component---src-pages-support-fragor-svar-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-webbsida-js": () => import("./../../../src/pages/support/webbsida.js" /* webpackChunkName: "component---src-pages-support-webbsida-js" */),
  "component---src-pages-terms-full-js": () => import("./../../../src/pages/terms-full.js" /* webpackChunkName: "component---src-pages-terms-full-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-villkor-anvandarvillkor-js": () => import("./../../../src/pages/villkor/anvandarvillkor.js" /* webpackChunkName: "component---src-pages-villkor-anvandarvillkor-js" */),
  "component---src-pages-villkor-index-js": () => import("./../../../src/pages/villkor/index.js" /* webpackChunkName: "component---src-pages-villkor-index-js" */),
  "component---src-pages-villkor-personuppgiftspolicy-js": () => import("./../../../src/pages/villkor/personuppgiftspolicy.js" /* webpackChunkName: "component---src-pages-villkor-personuppgiftspolicy-js" */)
}

